<template>
    <span>
        <svg class="icon" :class="classes" aria-hidden="true">
            <use :fill="color?color:'#90939A'"  :xlink:href="'#'+name"></use>
        </svg>
    </span>
</template>

<script>
    export default {
        props: [
            'name',
            'classes',
            'color'
        ]
    }
</script>

<style scoped>
    .icon{
        width: .2rem;
        height: .2rem;
    }
</style>